<template>
  <validation-observer
    ref="validator"
    v-slot="{ handleSubmit, invalid }"
  >
    <form @submit.prevent="handleSubmit(submit)">
      <slot :invalid="invalid" />
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver } from 'vee-validate';

export default {
  name: 'Index',
  components: {
    ValidationObserver,
  },
  methods: {
    submit() {
      return this.$listeners.submit();
    },
    setErrors(params) {
      this.$refs.validator.setErrors(params);
    },
    validate(params) {
      return this.$refs.validator.validate(params);
    },
  },
};
</script>
