<template>
  <div class="ui-w-100 ui-my-4 separator" />
</template>

<script>
export default {};
</script>

<style scoped>
.separator {
  height: 1px;
  background: #ccc;
  opacity: 0.48;
}
</style>
