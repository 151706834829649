<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-4 md:c-col-8 lg:c-col-6">
        <div class="ui-relative c-bg-white ui-rounded-md">
          <!-- Content -->
          <div class="c-row ui-h-full ui-p-8 ui-pb-0">
            <!-- Coluna esquerda -->
            <div class="c-col-full sm:c-col-1/2 ui-pb-8 sm:ui-mt-0">
              <div class="ui-flex ui-flex-col ui-h-full">
                <!-- Título e subtitulo -->
                <div class="ui-flex-1">
                  <!-- Titulo -->
                  <div class="c-text-subtitle">
                    <span>{{ title }}</span>
                  </div>

                  <!-- Subtitulo -->
                  <div class="c-text-b2 ui-mt-3">
                    <span>{{ subTitle }}</span>
                  </div>
                </div>

                <!-- Botão -->
                <div class="ui-pt-4 md:ui-inline-flex">
                  <c-button
                    full
                    size="lg"
                    @click="confirmButton.onClick"
                  >
                    {{ confirmButton.label }}
                  </c-button>

                  <c-button
                    v-if="cancelButton.label !== ''"
                    outline
                    full
                    size="lg"
                    class="ui-mt-4 md:ui-mt-0 md:ui-ml-4"
                    @click="cancelButton.onClick"
                  >
                    {{ cancelButton.label }}
                  </c-button>
                </div>
              </div>
            </div>

            <!-- Coluna direita -->
            <div class="c-col-full sm:c-col-1/2">
              <!-- Ilustração -->
              <div class="c-d-flex ui-h-full ui-justify-center">
                <img
                  :src="illustrationPath"
                  class="ui-mt-auto"
                  alt="Ilustração"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton } from '@estrategiahq/coruja-web-ui';

const types = {
  success: {
    illustrationPath: require('@/assets/images/p-modal/success.svg'),
  },
  warning: {
    illustrationPath: require('@/assets/images/p-modal/warning.svg'),
  },
  error: {
    illustrationPath: require('@/assets/images/p-modal/warning.svg'),
  },
};

export default {
  name: 'GenericModal',
  components: {
    CButton,
  },
  computed: {
    illustrationPath() {
      return types[this.type].illustrationPath;
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (type) => !!types[type],
    },
    confirmButton: {
      type: Object,
      default: () => ({
        onClick: () => this.$modal.hide(),
        label: 'Fechar',
      }),
    },
    cancelButton: {
      type: Object,
      default: () => ({
        onClick: () => this.$modal.hide(),
        label: '',
      }),
    },
  },
};
</script>
