<template>
  <div
    @mouseenter="isHover = true"
    @mouseleave="isHover = false"
    @click="!disabled && $emit('input', !value)"
  >
    <c-pill
      :label="label"
      :style="customStyles"
      :outline="outline || !value"
      :disabled="disabled"
      class="ui-cursor-pointer ui-transition-all ui-in-out ui-duration-300 ui-normal-case ui-whitespace-no-wrap"
    />
  </div>
</template>

<script>
import { CPill } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'PSelectablePill',
  components: {
    CPill,
  },
  data() {
    return {
      isHover: false,
    };
  },
  props: {
    value: Boolean,
    label: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'var(--c-color-primary)',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    outline: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    customStyles () {
      if (this.disabled) {
        return '--c-color-primary: var(--c-color-gray)';
      }
      return {
        '--c-color-primary': this.color,
        'border': `0.063rem solid ${this.value || this.isHover ? this.color : 'var(--c-color-gray-200)'}`,
        'color': this.value ? 'white' : this.isHover ? this.color : 'var(--c-color-gray-300)',
      };
    },
  },
};
</script>
