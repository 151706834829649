<template>
  <div class="c-container ui-w-full ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="c-col-full sm:c-col-1/2 md:c-col-4">
        <div class="modal-box ui-rounded c-bg-primary-300 ui-relative ui-p-6 ui-pl-4">
          <div class="ui-absolute ui-top-0 ui-right-0 ui-p-1">
            <button @click="$modal.hide()">
              <icon
                name="close"
                color="white"
              />
            </button>
          </div>
          <div>
            <p class="c-text-input c-text-white">
              {{ text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'TextOnlyModal',
  components: {
    Icon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
