<template>
  <p-form
    @submit="onSubmit"
    v-slot="{ invalid }"
  >
    <div class="c-row c-horizontal-center ui-mb-2">
      <div class="c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10 c-d-flex">
        <span class="c-text-h3">
          Alterar meu perfil
        </span>
      </div>
    </div>

    <div class="c-row c-horizontal-center">
      <!-- Interesses e redes sociais -->
      <div class="c-col-full md:c-col-1/2 lg:c-col-3">
        <div class="c-text-b2 c-text-gray-300 ui-mb-2">
          Inclua suas áreas de atuação ou estudo
        </div>
        <!-- Pills de interesses -->
        <div class="c-d-flex ui-overflow-x-auto sm:ui-overflow-hidden ui-overflow-y-hidden ui--mx-6 md:ui-mx-0">
          <div class="c-d-flex ui--mb-1 ui-flex-no-wrap sm:ui-flex-wrap ui-items-baseline ui-px-6 md:ui-px-0">
            <p-selectable-pill
              data-cy="selectablePill"
              v-for="interest in interests"
              :key="interest.id"
              :label="interest.name"
              v-model="interest.selected"
              class="ui-mr-2 ui-mb-2"
              :color="interest.color"
            />
          </div>
        </div>
        <div class="c-d-flex ui-mb-4">
          <div>
            <icon
              name="info"
              color="var(--c-color-gray-300)"
              class="ui-w-3 ui-mr-1"
              filled
            />
          </div>
          <span class="c-text-b3 c-text-gray-300 ui-pt-1">
            As áreas selecionadas serão exibidas em seu perfil público
          </span>
        </div>

        <separator class="ui-mb-4" />

        <!-- Inputs de redes sociais -->
        <validation-provider
          class="c-row ui-mb-3"
          v-for="socialNetwork in selectedSocialNetworks"
          :key="socialNetwork.id"
          :rules="{ validURI: socialNetwork.allowed_prefixes }"
          immediate
          v-slot="{ errors }"
        >
          <p-input
            :label="socialNetwork.name"
            v-model.trim="socialNetwork.profileSocialNetworkUrlPath"
            class="social-network-input ui-w-full ui-mb-2 ui-inline-flex c-col-full sm:c-col-1/2 md:c-col-full"
            :ref="socialNetwork.id"
            @handle-icon-click="removeInput(socialNetwork)"
            icon="trash"
            :data-cy="`inputSocialNetwork-${socialNetwork.name}`"
            :error-message="errors[0]"
          />
        </validation-provider>

        <!-- Seletor de redes sociais -->
        <div class="c-row ui-mb-3">
          <div class="c-col-full">
            <div class="c-text-b2 c-text-gray-300 ui-mb-2">
              Inclua suas redes sociais
            </div>
            <div class="c-d-flex ui-overflow-x-auto sm:ui-overflow-hidden ui-overflow-y-hidden ui--mx-6 md:ui-mx-0">
              <div class="c-d-flex ui--mb-1 ui-flex-no-wrap sm:ui-flex-wrap ui-items-baseline ui-px-6 md:ui-px-0">
                <p-selectable-pill
                  data-cy="selectablePill"
                  v-for="item in socialNetworks"
                  :key="item.id"
                  :label="item.name"
                  :disabled="item.selected"
                  :outline="true"
                  v-model="item.selected"
                  @input="setFocus(item.id)"
                  class="ui-mr-2 ui-mb-2 ui-inline-flex"
                  color="var(--c-color-gray-300)"
                />
              </div>
            </div>
            <div class="c-d-flex">
              <div>
                <icon
                  name="info"
                  color="var(--c-color-gray-300)"
                  class="ui-w-3 ui-mr-1"
                  filled
                />
              </div>
              <span class="c-text-b3 c-text-gray-300 ui-pt-1">
                As redes sociais serão exibidas em seu perfil público
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- Biografia -->
      <div class="c-col-full md:c-col-1/2 lg:c-col-7 ui-mb-4">
        <div class="ui-relative">
          <div class="c-d-flex ui-justify-end">
            <div
              @click="showInfoModal()"
              class="ui-cursor-pointer"
            >
              <icon
                name="Help"
                color="var(--c-color-gray-300)"
              />
            </div>
          </div>

          <separator />

          <validation-provider
            rules="max:500"
            v-slot="{ errors }"
          >
            <p-textarea
              data-cy="inputAboutMe"
              name="about_me"
              :hint="aboutMeHint"
              :error-message="errors[0]"
              v-model="aboutMe"
              placeholder="Conte algo sobre você adicionando uma biografia ao seu perfil"
              class="ui-h-48"
            />
          </validation-provider>
        </div>
      </div>
    </div>

    <separator />

    <!-- Botões voltar e salvar -->
    <div class="c-row c-horizontal-center">
      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <router-link :to="{ name: 'meu-perfil' }">
          <c-button
            data-cy="voltarButton"
            outline
            :disabled="isSubmitting"
            full
            class="ui-mb-4 sm:ui-mb-0"
          >
            Voltar sem salvar
          </c-button>
        </router-link>
      </div>

      <div class="c-col-full sm:c-col-1/4 lg:c-col-2">
        <c-button
          data-cy="updateSubmitButton"
          type="submit"
          :loading="isSubmitting"
          :disabled="invalid"
          full
        >
          Salvar alteração
        </c-button>
      </div>
    </div>
  </p-form>
</template>

<script>
import { mapGetters } from 'vuex';
import { PublicProfileStoreKeys } from '@/store/modules/public-profile';
import { UserStoreKeys } from '@/store/modules/user';
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';
import TexOnlyModal from '@/modals/text-only-modal';
import GenericModal from '@/modals/generic';
import Separator from '@/components/separator';
import PForm from '@/components/p-form';
import PInput from '@/components/p-input';
import PTextarea from '@/components/p-textarea';
import PSelectablePill from '@/components/p-selectable-pill';
import { ABOUT_ME } from '@/pages/profile/meu-perfil/modal-text-info';
import { ValidationProvider } from 'vee-validate';

const sortSelectedFirst = (a, b) => (+b.selected) - (+a.selected);

// TODO: Remover quando o DS exportar as medidas dos breakpoints em variáveis JS
const isMobile = () => window.innerWidth < 768;

export default {
  name: 'AlterarPerfilPage',
  components: {
    Icon,
    PSelectablePill,
    CButton,
    Separator,
    PForm,
    PInput,
    PTextarea,
    ValidationProvider,
  },
  data() {
    return {
      interests: [],
      socialNetworks: [],
      aboutMe: null,
    };
  },
  computed: {
    aboutMeHint() {
      if (!this.aboutMe) return '';
      return `Caracteres ${this.aboutMe.length}/500`;
    },
    selectedSocialNetworks() {
      return this.socialNetworks.filter(item => item.selected);
    },
    ...mapGetters({
      availableInterests: PublicProfileStoreKeys.GET_INTERESTS,
      availableSocialNetworks: PublicProfileStoreKeys.GET_SOCIAL_NETWORKS,
      user: UserStoreKeys.GET_LOGGED_USER,
      isSubmitting: UserStoreKeys.GET_REQUEST_LOADING,
    }),
  },
  async mounted() {
    await Promise.all([
      this.$store.dispatch(PublicProfileStoreKeys.ACTION_FETCH_SOCIAL_NETWORKS),
      this.$store.dispatch(PublicProfileStoreKeys.ACTION_FETCH_INTERESTS),
      this.$store.dispatch(UserStoreKeys.ACTION_FETCH_LOGGED_USER),
    ]).catch(this.showErrorModal);

    this.aboutMe = this.user.aboutMe;
    this.interests = this.mapUserInterests(this.user, this.availableInterests);
    this.socialNetworks = this.mapUserSocialNetworks(this.user, this.availableSocialNetworks);
  },
  methods: {
    showErrorModal() {
      this.$modal.show({
        component: GenericModal,
        props: {
          title: 'Não foi possível carregar os dados.',
          subTitle: 'Verifique sua conexão e atualize a página.',
          type: 'error',
          confirmButton: {
            label: 'Atualizar página',
            onClick: () => {
              this.$modal.hide();
              window.location.reload();
            },
          },
          cancelButton: {
            label: 'Cancelar',
            onClick: () => this.$modal.hide(),
          },
        },
      });
    },
    showInfoModal() {
      this.$modal.show({
        component: TexOnlyModal,
        props: { text: ABOUT_ME },
      });
    },
    removeInput(item) {
      item.selected = false;
    },
    setFocus(socialNetworkId) {
      this.$nextTick(() => {
        this.$refs[socialNetworkId][0].$el.querySelector('input').focus();
      });
    },
    async onSubmit() {
      const payload = {
        ...this.user,
        aboutMe: this.aboutMe,
        interests: this.interests.filter(i => i.selected).map(i => i.id),
        social_networks: this.selectedSocialNetworks.map(i => ({
          id: i.id,
          url_path: i.profileSocialNetworkUrlPath,
        })),
      };

      //salva dados do perfil social
      await this.$store.dispatch(UserStoreKeys.ACTION_UPDATE_LOGGED_USER_PUBLIC_PROFILE, payload);

      this.$router.push({ name: 'meu-perfil' });
    },
    mapUserInterests(user, availableInterests) {
      const interests = [];
      const interestsSet = new Set(user.interests.map(i => i.interestId));
      availableInterests.forEach(interest => {
        const i = { ...interest };
        i['selected'] = interestsSet.has(i.id);
        interests.push(i);
      });

      // Se for mobile deve ser ordenado pelos items já selecionados
      if (isMobile()) {
        interests.sort(sortSelectedFirst);
      }
      return interests;
    },
    mapUserSocialNetworks(user, availableSocialNetworks) {
      const socialNetworks = [];
      const socialNetworksMap = user.socialNetworks.reduce((acc, s) => {
        acc[s.socialNetworkId] = s;
        return acc;
      }, {});

      availableSocialNetworks.forEach(socialNetwork => {
        const sn = { ...socialNetwork };
        const selectedSocialNetwork = socialNetworksMap[sn.id];
        sn['profileSocialNetworkUrlPath'] = selectedSocialNetwork?.profileSocialNetworkUrlPath || '';
        sn['selected'] = !!selectedSocialNetwork;
        socialNetworks.push(sn);
      });

      // Se for mobile deve ser ordenado pelos items já selecionados
      if (isMobile()) {
        socialNetworks.sort(sortSelectedFirst);
      }
      return socialNetworks;
    },
  },
};
</script>

<style scoped>
/* Esse svg pertence a um componente importado que não tem prop pra receber classes externas como ui-cursor-pointer */
.social-network-input >>> svg {
  cursor: pointer;
}
</style>
